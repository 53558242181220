import OfficeTour from '@/office-tour';
import { SEO } from '@/common/seo';

const OfficeTourPage: Page = () => <OfficeTour />;

export default OfficeTourPage;

export const Head = () => (
    <SEO
        title="Zapata Dental Office - Phoenix, Arizona - Zapata Dental"
        description="Patients can wait in the beautiful lobby of Zapata Dental office while enjoying the live aquarium beside them."
        pathname="/office-tour/"
    />
);
