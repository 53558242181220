import { AltLayout } from '../../layouts/AltLayout';
import OfficeTourCarousel from '@/office-tour/components/office-tour-carousel';

const OfficeTour = () => (
    <AltLayout
        heroButtonText="Get In Touch With Us"
        heroImg="office.jpeg"
        label="Office Tour"
        title="Zapata Dental Office"
        description="Patients can wait in the beautiful lobby of Zapata dental office while enjoying the live aquarium besides them"
    >
        <OfficeTourCarousel />
    </AltLayout>
);

export default OfficeTour;
