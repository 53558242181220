import React, { useState } from 'react';
import Image from '@/common/image';
import Slider from 'react-slick';
import './office-carousel.css';
import { OfficeTourPageCarousel } from '../../../../constants';

interface tourPicProps {
    pic?: string;
    id?: number;
}

const OfficeTourCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState({ next: 0, current: 1 });

    const settings = {
        centerMode: true,
        centerPadding: '0px',
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: true,
        beforeChange: (current: number, next: number) => {
            setTimeout(() => {
                setCurrentSlide({ next, current });
            }, 300);
        },
        customPaging: (i: number) => {
            const item = OfficeTourPageCarousel[i];
            return (
                <div
                    className={`w-[160px] mr-6 text-center font-medium text-lg py-2 cursor-pointer border-2 mt-8 border-lightbrown rounded-lg  ${
                        i !== currentSlide.next
                            ? 'text-lightbrown bg-white'
                            : 'bg-lightbrown text-white'
                    }`}
                >
                    {item.name}
                </div>
            );
        },
        dotsClass: 'office-tour-pagination no-scrollbar',
        responsive: [
            {
                breakpoint: 639,
                settings: {
                    centerPadding: '40px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const TourPics = ({ pic, id }: tourPicProps) => (
        <div
            className={`relative mx-3
                ${
                    currentSlide.next === id
                        ? 'z-0 sm:z-10 py-0 sm:py-11 my-0 sm:my-5 sm:scale-in'
                        : 'z-0 py-0 sm:py-10 my-0 sm:my-7'
                }
                ${currentSlide.current == id && 'sm:scale-out '}
            `}
        >
            <div className="grid rounded-lg relative mx-1 bg-redbg ">
                <Image
                    filename={pic}
                    className="cursor-pointer rounded-lg -mt-4 -ml-4 mr-2 mb-2 lg:mb-3"
                    alt={`office-tour${id}`}
                />
            </div>
        </div>
    );

    return (
        <div className="py-16 lg:py-[120px] lg:-mb-24">
            <div className="DH2MH3 text-primary mb-6 sm:mb-0 text-left sm:text-center">
                Our Office Tour
            </div>

            <Slider {...settings}>
                {OfficeTourPageCarousel.map((item, ind) => (
                    <TourPics pic={item.pic} id={ind} key={ind} />
                ))}
            </Slider>
        </div>
    );
};
export default OfficeTourCarousel;
